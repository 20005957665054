import React from "react"
import PropTypes from 'prop-types'
import * as style from './pagedata.module.scss'

const PageData = ({ megaNumber, miniText }) => {
  return (
    <div className={style.pagedata + ' hidden-mobile'}>
      <div className={style.minitext}>{miniText}</div>
      <div className={style.meganumber}>{megaNumber}</div>
    </div>
  )
}

PageData.propTypes = {
    megaNumber: PropTypes.string.isRequired,
    miniText: PropTypes.string.isRequired
}

export default PageData
