// extracted by mini-css-extract-plugin
export var active = "allnews-module--active--fETn-";
export var allNewsWrap = "allnews-module--all-news-wrap--HG9u7";
export var columnContent = "allnews-module--column-content--cC-CZ";
export var columnImage = "allnews-module--column-image--p5Lbd";
export var date = "allnews-module--date--eU92o";
export var header = "allnews-module--header--xypLq";
export var headerInner = "allnews-module--header-inner--x4a6e";
export var imageToggle = "allnews-module--image-toggle--RHTOC";
export var imageToggleMobile = "allnews-module--image-toggle-mobile--Fg73p";
export var inactive = "allnews-module--inactive--fUsGx";
export var item = "allnews-module--item--vs6cj";
export var meta = "allnews-module--meta---MB6b";
export var newsFilter = "allnews-module--news-filter--TqyeL";
export var newsFilterLabel = "allnews-module--news-filter-label--nMTaH";
export var option = "allnews-module--option--4UnYA";
export var optionActive = "allnews-module--option-active--PFeYK";
export var placeholder = "allnews-module--placeholder---wyis";
export var readMore = "allnews-module--read-more--V5ho+";
export var toggleLabel = "allnews-module--toggle-label--W5-DH";
export var toggleOption = "allnews-module--toggle-option--wlHB9";
export var toggleOptionActive = "allnews-module--toggle-option-active--mVAXk";