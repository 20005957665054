import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

import AllNewsWrap from "./allNewsWrap"

const AllNews = () => {
  const data = useStaticQuery(graphql`{
  allWpMention(sort: {fields: MentionData___date, order: DESC}) {
    nodes {
      title
      MentionData {
        date(formatString: "D MMMM y")
        url
        type
        market {
          name
          databaseId
        }
      }
      featuredImage {
        node {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(height: 320, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`)

  return <AllNewsWrap news={data.allWpMention.nodes} />
}

export default AllNews
