import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AllNews from '../components/news/allNews'
import Description from "../components/description"
import PageData from "../components/pageData"

import Mini1 from '../images/blobs/mini1.inline.svg'
import Mini2 from '../images/blobs/mini2.inline.svg'

const PageNews = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout id="news">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      <div className="page-header">
        <PageData {...page.PageData} />
        <div className="page-header-copy">
          <h1>{page.title}</h1>
          <Description {...page.Description} />
        </div>
      </div>

      <div className="blob-wrap small">
        <Mini1 style={{left: '-20%'}} />
      </div>
      <div className="blob-wrap small">
        <Mini2 style={{right: '-20%', transform: 'translateY(130%)'}} />
      </div>
      <AllNews />
    </Layout>
  )
}

export default PageNews

export const query = graphql`
  query($slug: String) {
    wpPage(slug: { eq: $slug }) {
      title
      PageData {
        megaNumber
        miniText
      }
      Description {
        description
      }
      SeoMeta {
        seoTitle
        seoDescription
      }
    }
  }`
