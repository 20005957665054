import React from "react"
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import * as style from './allnews.module.scss'
import placeholder from '../../images/news-placeholder.jpg'

const PlaceholderImage = () => {
  return (
    <div className="gatsby-image-wrapper" style={{overflow:"hidden"}}>
      <img src={placeholder} alt="Mediahub" className={style.placeholder} />
    </div>
  )
}

const FeaturedImage = ({ image }) => {
  return (
    <GatsbyImage
      image={image.localFile.childImageSharp.gatsbyImageData}
      alt={image.altText}
      title={image.title} />
  );
}

const changeMonthName = (dateTime) => {

  let newDate = dateTime

  const datesEn = ["January","February","March","April","May","June","July","August","September","October","November","December"]
  const datesPl = ["stycznia","lutego","marca","kwietnia","maja", "czerwca", "lipca", "sierpnia", "września", "października", "listopada", "grudnia"]

  const splitedDateArray = dateTime.split(" ")

  const monthName = splitedDateArray[1]

  const monthIndex = datesEn.indexOf(monthName)

  const monthNamePl = datesPl[monthIndex]

  return newDate.replace(monthName, monthNamePl)

}


const AllNewsItem = (props) => {

  console.log("props",props);

  const { title, MentionData, featuredImage, showImage } = props
  const image = featuredImage ? <FeaturedImage image={featuredImage.node} /> : <PlaceholderImage />


  const dateFormatted = changeMonthName(MentionData.date)


  return (
    <a key={title} href={MentionData.url} className={style.item} target="_blank" rel="noreferrer">
      { showImage && (
        <div className={style.columnImage}>
          {image}
        </div>
      )}
      <div className={style.columnContent} style={showImage ? {maxWidth: '380px'} : {}}>
        <div className={style.date}>{dateFormatted}</div>
        <div className={style.meta}>{MentionData.type} &ndash; {MentionData.market && (MentionData.market.name)}</div>
        <h4>{title}</h4>
        <span className={style.readMore}>Czytaj więcej &rarr;</span>
      </div>
    </a>
  )
}

AllNewsItem.propTypes = {
  title: PropTypes.string.isRequired,
  MentionData: PropTypes.object.isRequired,
  featuredImage: PropTypes.object
}

export default AllNewsItem
