import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import AllNewsItem from './allNewsItem'
import FilterGroup from '../filterGroup'
import FilterWrap from '../filterWrap'
import FilterReset from '../filterReset'
import { getMarkets, getCategories } from '../../helper'
import { filterStyles } from '../filterStyles'

import * as style from './allnews.module.scss'
import grid from '../../images/icons/grid-inactive.svg'
import gridActive from '../../images/icons/grid-active.svg'
import list from '../../images/icons/list-inactive.svg'
import listActive from '../../images/icons/list-active.svg'

const NEWS_TO_DISPLAY = 6

class AllNewsWrap extends React.Component {
  constructor(props) {
    super()
    this.state = {
      markets: null,
      categories: null,
      showImage: true,
      showCount: NEWS_TO_DISPLAY
    }

    this.categories = getCategories(props.news)
    this.markets = getMarkets(props.news)
    this.handleLoadMore = this.handleLoadMore.bind(this)
    this.handleImageToggle = this.handleImageToggle.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleReset() {
    this.setState({
      markets: null,
      categories: null
    })
  }

  handleFilterBlur(event, label) {
    this.setState({[label]: {...event}})
  }

  handleLoadMore() {
    this.setState({
      showCount: this.state.showCount + NEWS_TO_DISPLAY
    })
  }

  handleImageToggle() {
    this.setState(prevState => ({
      showImage: !prevState.showImage
    }))
  }

  render() {
    let allNews = this.props.news
    if (this.state.categories && this.state.categories.value) {
      allNews = allNews.filter(news => news.MentionData.type === this.state.categories.value)
    }
    if (this.state.markets && this.state.markets.value) {
      allNews = allNews.filter(news => news.MentionData.market && news.MentionData.market.name === this.state.markets.value)
    }


    return (
      <section>
        <div className={style.imageToggleMobile + ' hidden-desktop'}>          
          <button onClick={this.handleImageToggle}>
            <span className={this.state.showImage ? style.optionActive : style.option }>Widok siatki</span>
            <span className={this.state.showImage ? style.option : style.optionActive }>Widok listy</span>
          </button>
        </div>
        <FilterGroup>
          <FilterWrap>
            <Select
              styles={filterStyles}
              placeholder='Wybierz kategorię...'
              onChange={ (event) => { this.handleFilterBlur(event, 'categories') } }
              options={this.categories}
              value={this.state.categories}
              isSearchable={false}
            />
          </FilterWrap>
          <FilterWrap>
            <Select
              styles={filterStyles}
              placeholder='Wybierz rynek...'
              onChange={ (event) => { this.handleFilterBlur(event, 'markets') } }
              options={this.markets}
              value={this.state.markets}
              isSearchable={false}
            />
          </FilterWrap>
          <button onClick={this.handleImageToggle} className={style.imageToggle + ' hidden-mobile'}>
            <div className={!this.state.showImage ? style.toggleOption : style.toggleOptionActive }>
              <img src={grid} alt="" className={style.inactive} />
              <img src={gridActive} alt="" className={style.active} />
            </div>
            <div className={this.state.showImage ? style.toggleOption : style.toggleOptionActive }>
              <img src={list} alt="" className={style.inactive} />
              <img src={listActive} alt="" className={style.active} />
            </div>
          </button>
        </FilterGroup>

        <div className={style.allNewsWrap}>
          {allNews
            .slice(0, this.state.showCount)
            .map(news => <AllNewsItem key={news.title} {...news} showImage={this.state.showImage} />)
          }
          { allNews.length === 0 && (
            <FilterReset callback={this.handleReset} />
          )}
        </div>

        {allNews.length > this.state.showCount && (
          <div className="mh-button-wrap">
            <button className="mh-button" onClick={this.handleLoadMore}>Załaduj więcej</button>
          </div>
        )}
      </section>
    )
  }
}

AllNewsWrap.propTypes = {
  news: PropTypes.array.isRequired
}

export default AllNewsWrap
