import React from "react"
import * as style from './filterReset.module.scss'

const FilterReset = ({ callback }) => {
  return (
    <div className={style.filterReset}>
      <div className={style.wrap}>
        <h3>Brak wyników</h3>
        <p>Nie znaleźliśmy żadnych wyników dla tego wyboru filtra. Zresetuj filtr i spróbuj innego&nbsp;wyszukiwania.</p>
        <button onClick={callback}>Resetuj filtry</button>
      </div>
    </div>
  )
}

export default FilterReset
